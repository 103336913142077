import React, { Component } from "react";
import ie from "ie-version";
import thunder from "../../assets/images/thunder.png";
import headless from "../../assets/images/headless.png";
import scream from "../../assets/images/scream.png";

import { LocationMap } from "../constants";

import arrow from "../../assets/images/arrow.png";
import trapIcon from "../../assets/images/trap.png";
import trapGlow from "../../assets/images/trap_glow.png";

import HouseRoomImages from "../../assets/images/rooms";
import locationImages from "../../assets/images/locations";

import ErrorMessage from "../ErrorMessage";
import TrapButton from "../TrapButton";
import ModalHeader from "../ModalHeader";

const TrapImageMap = {
  1: headless,
  2: thunder,
  3: scream
};

const InstructionsBanner = ({ onBack }) => (
  <div className="banner blue-bg house">
    <p className="banner-instructions">Pick a spot for the trap</p>
    <button className="back-btn" onClick={onBack} alt="back">
      <img className="arrow" src={arrow} alt="arrow" />
    </button>
  </div>
);

export default class RoomPage extends Component {
  state = {
    glowOpacity: 0,
    error: null,
    selectedLocation: null
  };

componentDidMount() {
    this.t = new Date();
    this.glower = setInterval(() => {
      let elapsed = new Date() - this.t;
      elapsed *= 0.0045;
      let glowOpacity = Math.sin(elapsed);
      glowOpacity = ((glowOpacity + 1) / 2) * 0.8;
      this.setState({ glowOpacity });
    }, 1000 / 10);
  }

  componentWillUnmount() {
    clearInterval(this.glower);
  }

  locationStyle = (roomId, locationId) => {
    const { x = 0, y = 0 } = LocationMap[roomId][locationId] || {};

    return { top: `${y}%`, left: `${x}%` };
  };

  onCloseError = () => {
    this.setState({ error: null });
  };

  onCloseModal = () => {
    this.setState({ selectedLocation: null });
  };

  onSelectLocation = locationId => () => {
    this.setState({ selectedLocation: locationId });
  };

  onSelectTrap = trap => async () => {
    const { player, updateTraps } = this.props;
    const { selectedLocation } = this.state;

    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      let r = await fetch(`${process.env.REACT_APP_API_URL}/api/game/traps`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          ...player,
          location: Number(selectedLocation),
          trap
        })
      });
      if (r.status !== 200) {
        this.setState({ error: "Could Not Choose Trap!" });
        return;
      }

      r = await r.json();
      updateTraps(r.locations);

      this.setState({ selectedLocation: null });
    } catch (e) {
      this.setState({ error: "Could Not Place Trap!" });
      return;
    }
  };

  renderTrap = locationId => {
    const { glowOpacity } = this.state;
    const { locationMap, roomId } = this.props;
    const locationStyle = this.locationStyle(roomId, locationId);
    const location = locationMap[locationId];

    if (location && location.trap) {
      const trapImage = TrapImageMap[location.trap];

      return (
        <div className="trap-icon" style={locationStyle}>
          <img src={trapImage} alt="trap" />
          <p>{location.player}</p>
        </div>
      );
    }

    // return <div className="position" style={ locationStyle } onClick={ this.onSelectLocation( locationId ) } />
    return (
      <div className="position" style={locationStyle}>
        <img
          src={trapIcon}
          onClick={this.onSelectLocation(locationId)}
          alt="trap-spot"
        />
        {!ie.version && (
          <img
            className="trap-overlay"
            style={{ opacity: glowOpacity }}
            src={trapGlow}
            onClick={this.onSelectLocation(locationId)}
            alt="trap-glow"
          />
        )}
      </div>
    );
  };

  render() {
    const { back, backToFloor, roomId, screenHeight } = this.props;
    const { error, selectedLocation } = this.state;
    // console.log(this.props)
    return (
      <div className="black-bg map-page-container">
        <div id="preload">&nbsp;</div>
        {error && (
          <ErrorMessage
            message="Unable to join game!"
            onClick={this.onCloseError}
          />
        )}
        <InstructionsBanner onBack={back} />
        <div className="map-container room">
          <img
            className="map"
            style={{ height: screenHeight }}
            src={HouseRoomImages[roomId]}
            alt={`room${roomId}`}
          />

          {/* Traps modal */}
          <div className={`modal ${selectedLocation !== null && "is-active"}`}>
            <div className="modal-background"></div>
            <div
              className="modal-card"
              style={{ overflow: "visible", padding: 0 }}
            >
              <ModalHeader message="Choose trap" onClose={this.onCloseModal} />
              {selectedLocation && (
                <img
                  src={locationImages[selectedLocation]}
                  alt="selected-location"
                />
              )}
              <section className="modal-card-body trap-modal-container blue-bg">
                <TrapButton
                  label="Headless"
                  image={headless}
                  onClick={this.onSelectTrap(1)}
                />
                <TrapButton
                  label="Thunder"
                  image={thunder}
                  onClick={this.onSelectTrap(2)}
                />
                <TrapButton
                  label="Scream"
                  image={scream}
                  onClick={this.onSelectTrap(3)}
                />
              </section>
            </div>
          </div>

          <div className="floor-picker blue-bg">
            <button className="floor-choice" onClick={backToFloor(1)}>
              {" "}
              1st Floor{" "}
            </button>
            <div
              style={{
                borderWidth: 0,
                borderRightWidth: 1,
                borderColor: "white",
                borderStyle: "solid",
                height: "100%"
              }}
            />
            <button className="floor-choice" onClick={backToFloor(2)}>
              {" "}
              2nd Floor{" "}
            </button>
          </div>

          <div className="map-controls-container">
            {Object.keys(LocationMap[roomId]).map(locationId =>
              this.renderTrap(locationId)
            )}
          </div>
        </div>
      </div>
    );
  }
}
