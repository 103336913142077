import React from 'react';
import { useSelector } from 'react-redux';
import HousePage from './housePage_container';
import ShrinePage from './shrinePage_container';

export const MapPage = () => {
  const map = useSelector((state) => state.game.map);

  switch (map) {
    case 'House':
      return <HousePage />;
    case 'Shrine':
      return <ShrinePage />;
    default:
      return map === 'House' ? <HousePage /> : <ShrinePage />;
  }
};

export default MapPage;
