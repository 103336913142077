import React from 'react';

export const PickSectionBanner = ({ name }) => (
  <>
    <p className="hi-player">
    {
      `Hi ${name}!`
    }
    </p>
    <p className="banner-instructions">Click on a section to zoom in</p>
  </>
);
