import location1 from './Shrine6.jpg';
import location2 from './Entrance2.jpg';
import location3 from './Entrance1.jpg';
import location4 from './Shrine5.jpg';
import location5 from './Shrine4.jpg';
import location6 from './Shrine3.jpg';
import location7 from './Torii_Tunnel1.jpg';
import location8 from './Torii_Tunnel3.jpg';
import location9 from './Torii_Tunnel2.jpg';
import location10 from './Cave3.jpg';
import location11 from './Cave1.jpg';
import location12 from './Cave2.jpg';
import location13 from './Grave1.jpg';
import location14 from './Grave4.jpg';
import location15 from './Grave3.jpg';
import location16 from './Grave2.jpg';
import location17 from './Grave6.jpg';
import location18 from './Grave5.jpg';
import location19 from './Shrine2.jpg';
import location20 from './Shrine1.jpg';

export const shrineLocationImages = {
  1: location1,
  2: location2,
  3: location3,
  4: location4,
  5: location5,
  6: location6,
  7: location7,
  8: location8,
  9: location9,
  10: location10,
  11: location11,
  12: location12,
  13: location13,
  14: location14,
  15: location15,
  16: location16,
  17: location17,
  18: location18,
  19: location19,
  20: location20,
};

export default shrineLocationImages;
