export const TrapImageMap = {
  1: 'ghost',
  2: 'thunder',
};

// export const RoomThe = {
//   1: true,
//   2: true,
//   3: false,
//   4: true,
//   5: true,
//   6: true,
//   7: true,
//   8: true,
//   9: false,
//   10: true,
//   11: true,
//   12: true,
// }

export const RoomNames = {
  1: 'Living Room',
  2: 'Kitchen',
  3: "Reiko's Bedroom",
  4: 'Downstairs Bathroom',
  5: 'Office',
  6: 'Downstairs Foyer',
  7: 'Baby Room',
  8: 'Shrine Room',
  9: "Miko's Room",
  10: 'Upstairs Bathroom',
  11: 'Tea Room',
  12: 'Upstairs Foyer',
  13: 'Entrance',
  14: 'Shrine Main Room',
  15: 'Grave',
  16: 'Cave',
  17: 'Entrance',
};

export const LocationMap = {
  1: {
    11: { x: 24, y: 41 },
    12: { x: 15, y: 53 },
    13: { x: 18, y: 69 },
    10: { x: 75, y: 31 },
  },
  2: {
    14: { x: 14, y: 28 },
    17: { x: 79, y: 45 },
    16: { x: 78, y: 70 },
    15: { x: 12, y: 71 },
  },
  3: {
    8: { x: 28, y: 40 },
    9: { x: 26, y: 70 },
    7: { x: 84, y: 32 },
  },
  4: {
    6: { x: 81, y: 29 },
    5: { x: 80, y: 62 },
  },
  5: {
    4: { x: 20, y: 24 },
    3: { x: 83, y: 24 },
    2: { x: 82, y: 71 },
    1: { x: 53, y: 80 },
  },
  6: {
    18: { x: 52, y: 66 },
    19: { x: 42, y: 91 },
  },
  7: {
    101: { x: 14, y: 71 },
    102: { x: 15, y: 28 },
    103: { x: 85, y: 28 },
  },
  8: {
    104: { x: 54, y: 38 },
    105: { x: 60, y: 71 },
    // 106: {x: 80, y: 47},
  },
  9: {
    107: { x: 24, y: 36 },
    108: { x: 68, y: 60 },
    109: { x: 73, y: 32 },
  },
  10: {
    110: { x: 26, y: 25 },
    111: { x: 20, y: 72 },
    112: { x: 80, y: 40 },
    113: { x: 71, y: 69 },
  },
  11: {
    114: { x: 65, y: 14 },
    115: { x: 78, y: 78 },
  },
  12: {
    116: { x: 73, y: 50 },
    117: { x: 69, y: 86 },
  },
  13: {
    118: { x: 73, y: 50 },
  },
};

export const RoomMap = {
  1: 5,
  2: 5,
  3: 5,
  4: 5,
  5: 4,
  6: 4,
  7: 3,
  8: 3,
  9: 3,
  10: 1,
  11: 1,
  12: 1,
  13: 1,
  14: 2,
  15: 2,
  16: 2,
  17: 2,
  18: 6,
  19: 6,
  101: 7,
  102: 7,
  103: 7,
  104: 8,
  105: 8,
  106: 8,
  107: 9,
  108: 9,
  109: 9,
  110: 10,
  111: 10,
  112: 10,
  113: 10,
  114: 11,
  115: 11,
  116: 12,
  117: 12,
};

export const mapID = {
  House: 0,
  Shrine: 1,
};

export const trapData = {
  0: {
    0: ['flicker', 0.27],
    1: ['slam', 0.64],
    2: ['whoosh', 1.0],
  },
  1: {
    0: ['fog', 0.27],
    1: ['whoosh', 0.64],
    2: ['hands', 1.0],
  },
};
