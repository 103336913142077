import { combineReducers, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { game, player } from './reducers';

import * as _actions from './actions';

export const actions = Object.freeze({
  ..._actions,
});

const reducers = persistReducer(
  {
    key: 'root',
    storage,
  },
  combineReducers({
    game,
    player,
  }),
);
const store = createStore(reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export const persistor = persistStore(store);

export default store;
