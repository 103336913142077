import {
  CLEAR_GAME,
  CLEAR_PLAYER,
  SET_GAME,
  SET_KILL_PLAYER,
  SET_PLAYER,
  SET_PLAYER_ROOM,
  SET_STATUS,
  UPDATE_MAP,
  SET_SCORES,
} from './actions';

const InitialGameState = {};

export const game = (state = InitialGameState, action) => {
  switch (action.type) {
    case CLEAR_GAME:
      return {};
    case SET_SCORES:
      return {
        ...state,
        scores: action.scores,
      };
    case SET_GAME:
      return action.game;
    case SET_PLAYER_ROOM:
      return {
        ...state,
        playerRoom: action.room,
      };
    case SET_KILL_PLAYER:
      return {
        ...state,
        killPlayer: action.killPlayer,
      };
    case SET_STATUS:
      return {
        ...state,
        status: action.status,
      };
    case UPDATE_MAP: {
      const newState = {
        ...state,
        locations: action.locations,
      };
      return newState;
    }
    default:
      return state;
  }
};

export const player = (state = {}, action) => {
  switch (action.type) {
    case CLEAR_PLAYER:
      return {};
    case SET_PLAYER:
      return {
        gameCode: action.gameCode,
        token: action.token,
        name: action.name,
      };
    default:
      return state;
  }
};
