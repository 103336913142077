import React from 'react';

export const PlayerLocationBanner = ({
  roomName,
  onCloseClicked,
  timeRemaining,
  score,
}) => (
  <>
    <div className="banner-header">
      {/* Score Tracker */}
      <div className="score-container">
        <div className="player-score">
          <p className="player-score"> SCORE </p>
        </div>
        <div className="score-tracker">
          <p className="score-tracker red">
            {' '}
            {score}
            {' '}
          </p>
        </div>
      </div>

      {/* Timer */}
      {/* <div className="timer-container">
        <div className="time">
          <p className="timer"> TIME </p>
        </div>
        <time className="time-remaining red">
          {' '}
          {timeRemaining}
          {' '}
        </time>
      </div> */}

      {/* Player Location Tracker */}
      <div className="location-container">
        <div className="location">
          <p className="player-location">PLAYER LOCATION:</p>
        </div>
        <span className="room-name red">{roomName}</span>
      </div>

      <div className="close">
        <button
          className="delete is-large"
          aria-label="close"
          onClick={onCloseClicked}
        />
      </div>
    </div>
  </>
);
