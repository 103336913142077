import React from 'react';

export const PickRoomBanner = ({ name }) => (
  <>
    <p className="hi-player house">
    {`Hi ${name}!`}
    </p>
    <p className="banner-instructions house">Pick a room for the trap</p>
  </>
);
