import React, { Component } from "react";
import ReactDOM from "react-dom";
import RoomPage from "./roomPage_container";
import WS, { InitializeWS, CloseWS } from "../../ws";

import ModalHeader from "../ModalHeader";
//Refactor following into one file
import { PlayerLocationBanner } from "../banner/PlayerLocationBanner";
import { RoomNames } from "../constants";
import { PickRoomBanner } from "../PickRoomBanner";
import { Button } from "react-bulma-components";
import ScoreTracker from '../scoreTracker';

import fb from "../../assets/images/fb.png";
import ig from "../../assets/images/ig.png";
import flicker from "../../assets/images/flicker_btn.png";
import slam from "../../assets/images/slam_btn.png";
import whoosh from "../../assets/images/whoosh_btn.png";
import manaBar from "../../assets/images/mana_bar_blue.png";
import manaBarBg from "../../assets/images/mana_bar_bg.png";
import manaLevel from "../../assets/images/mana_level.png";
import location from "../../assets/images/location.png";
import headless from "../../assets/images/headless.png";
import thunder from "../../assets/images/thunder.png";
import scream from "../../assets/images/scream.png";
import floor1 from "../../assets/images/floor1.jpg";
import floor2 from "../../assets/images/floor2.jpg";
import floor1s from "../../assets/images/floor1_s.jpg";
import floor2s from "../../assets/images/floor2_s.jpg";

const FULL_MANA_SECS = 45;
const CoolDowns = {
  flicker: 0.27,
  slam: 0.64,
  whoosh: 1.0
};
const TrapImageMap = {
  1: headless,
  2: thunder,
  3: scream
};
const FloorImageMap = {
  1: floor1,
  2: floor2,
  3: floor1s,
  4: floor2s
};

export default class HousePage extends Component {
  state = {
    activeFloor: 1,
    activeRoom: null,
    confirmQuit: false,
    selectedTrap: null,
    mana: 0,
    screenWidth: 0,
    screenHeight: window.innerHeight,
    isShowRealtimeTraps: false,
    timeRemaining: null,
    currScore: 0,
    trapName: null,
    pointsEarned: null,
  };
  lastScareDate = undefined;
  realtimePicker = React.createRef();

  componentWillMount() {
    const { gameCode, token } = this.props.player;
    InitializeWS(gameCode, token);
    WS().handleOpen = this.handleWSOpen;
    WS().handleMessage = this.handleWSMessage;
  }

  componentDidMount() {
    this.checkSession();
    window.addEventListener("resize", this.onResizeScreen);

    if (this.props.status === "started") {
      this.onResizeScreen();
      this.startTimer();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResizeScreen);
  }

  onResizeScreen = () => {
    this.setState({ screenHeight: window.innerHeight });
    const d = ReactDOM.findDOMNode(this.realtimePicker.current);
    if (d) {
      this.setState({ screenWidth: d.offsetWidth });
    }
  };

  componentWillReceiveProps(props) {
    const { playerRoom, status } = props;
    if (status !== this.props.status && status === "started") {
      // start mana refill
      this.lastScareDate = new Date();
      this.onResizeScreen();
      this.startTimer();
    }
    if (playerRoom && this.props.playerRoom !== playerRoom) {
      this.setState({
        activeFloor: playerRoom <= 6 ? 1 : 2
      });
    }
  }

  startTimer = () => {
    if (!this.timer) {
      this.lastTime = new Date();
      this.timer = setInterval(() => {
        const date = new Date();
        let dt = date - this.lastTime;
        this.lastTime = date;
        dt /= 1000;
        let mana = this.state.mana + dt / FULL_MANA_SECS;
        if (mana > 1) {
          mana = 1;
          clearInterval(this.timer);
          this.timer = undefined;
        }
        this.setState({ mana });
      }, 1000 / 60);
    }
  };

  checkSession = async () => {
    const { clearPlayer, player, setGame } = this.props;
    const { gameCode, token } = player;
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      let r = await fetch(
        `${process.env.REACT_APP_API_URL}/api/game/map?gameCode=${gameCode}&token=${token}`,
        {
          method: "GET",
          headers: headers
        }
      );
      if (r.status !== 200) {
        clearPlayer();
        return;
      }
      setGame(await r.json());
    } catch (e) {
      clearPlayer();
    }
  };

  forceClearPlayer = async () => {
    const { clearPlayer } = this.props;
    clearPlayer();
  };

  handleWSOpen = () => {
    console.log("connected websocket");
  };

  handleWSMessage = msg => {
    const { setKillPlayer, setPlayerRoom, setStatus, updateTraps, setScores } = this.props;
    
    msg = JSON.parse(msg);
    
    const {
      name
    } = this.props.player

    switch (msg.event) {
      case "score_updated":
        if(name === msg.name) {
          this.setState({
            currScore: msg.score,
            pointsEarned: msg.pointsEarned,
            reason: msg.reason,
          })
        }
        break;
      case "choose_trap":
        updateTraps(msg.locations);
        break;
      case "start":
        this.onBackToFloorplan();
        setStatus("started");
        break;
      case "player_room":
        setPlayerRoom(msg.room);
        break;
      case "end":
        CloseWS();
        setScores(msg.scores);
        setStatus(msg.status);
        if (msg.killPlayer) {
          setKillPlayer(msg.killPlayer);
        }
        break;
      case "time_remaining":
        this.setState({
          timeRemaining: msg.time,
        })
        break;
      case 'created':
        break;
      default:
        break;
    }
  };

  onBackToFloorplan = () => {
    this.setState({ activeRoom: null });
  };

  onBackToFloor = activeFloor => () => {
    this.setState({
      activeRoom: null,
      activeFloor
    });
  };

  onSelectRealtimeTrap = (gameCode, trap, name, mana) => e => {
    const coolDown = CoolDowns[trap];
    if (mana < coolDown) {
      return;
    }

    WS().sendRealTimeTrap(gameCode, trap, name);
    this.setState({
      mana: mana - coolDown
    });
    // reset timer
    this.lastScareDate = new Date();
    if (!this.timer) {
      this.startTimer();
    }
  };

  onSelectRoom = room => () => {
    if (this.props.status !== "started") {
      this.setState({ activeRoom: room });
    }
  };

  onSelectFloor = floor => e => {
    e.preventDefault();

    this.setState({ activeFloor: floor });
  };

  setConfirmQuit = confirmQuit => () => {
    this.setState({ confirmQuit: confirmQuit });
  };

  renderPlayerRoom() {
    const { playerRoom } = this.props;
    if (!playerRoom) {
      return null;
    }

    const { x, y } = {
      1: { x: 28, y: 33 },
      2: { x: 25, y: 52 },
      3: { x: 64, y: 20 },
      4: { x: 69, y: 38 },
      5: { x: 77.5, y: 63 },
      6: { x: 45.5, y: 66 },
      7: { x: 35.5, y: 35 },
      8: { x: 30, y: 54 },
      9: { x: 70, y: 24 },
      10: { x: 75, y: 47 },
      11: { x: 78, y: 68 },
      12: { x: 55, y: 65 }
    }[playerRoom];

    return (
      <img
        className="player-location-icon"
        style={{ top: `${y}%`, left: `${x}%` }}
        src={location}
        alt="player_location"
      />
    );
  }

  renderTrapChoices() {
    let trapPositions;

    if (this.props.status === "started") {
      trapPositions = {
        1: { x: 16, y: 27 },
        2: { x: 14, y: 46.5 },
        3: { x: 52, y: 15.5 },
        4: { x: 61, y: 31 },
        5: { x: 68, y: 53 },
        6: { x: 38, y: 61 },
        7: { x: 22, y: 29 },
        8: { x: 18, y: 50 },
        9: { x: 59, y: 15 },
        10: { x: 67, y: 39 },
        11: { x: 73.5, y: 61.5 },
        12: { x: 44, y: 62 }
      };
    } else {
      trapPositions = {
        1: { x: 10.5, y: 29 },
        2: { x: 8.5, y: 51.5 },
        3: { x: 54, y: 15.5 },
        4: { x: 64.5, y: 34.5 },
        5: { x: 70.5, y: 58.5 },
        6: { x: 38, y: 68.5 },
        7: { x: 17.5, y: 31 },
        8: { x: 12, y: 56.5 },
        9: { x: 60.5, y: 14.5 },
        10: { x: 72.5, y: 43.5 },
        11: { x: 78.5, y: 70.5 },
        12: { x: 43.5, y: 70 }
      };
    }

    const locations = [];
    this.props.rooms.forEach((room, i) => {
      if (Math.ceil((i + 1) / 6) === this.state.activeFloor) {
        room.forEach((l, j) => {
          locations.push({
            room: i + 1,
            position: j,
            trap: l.trap,
            player: l.player
          });
          j++;
        });
      }
    });

    return locations.map(l => {
      const { x, y } = trapPositions[l.room];
      const trapImage = TrapImageMap[l.trap];

      return (
        <div
          key={`${l.room}-${l.position}`}
          className="floorplan-trap-icon"
          style={{ top: `${y + l.position * 4}%`, left: `${x}%` }}
        >
          <img src={trapImage} alt="trap" />
          <p>{l.player}</p>
        </div>
      );
    });
  }

  renderRealtimeChoices() {
    const { status, player } = this.props;
    const { mana, screenWidth } = this.state;
    const { gameCode } = player;
    const level = 90 * mana;
    let opacity = 0.65;
    const flash = 0.7 / FULL_MANA_SECS;
    Object.values(CoolDowns).forEach(v => {
      if (v < mana && mana < v + flash) {
        const factor = 1 - (mana - v) / flash;
        opacity = factor + (1 - factor) * opacity;
      }
    });
    if (mana === 1) {
      opacity = 1;
    }

    return (
      <div
        ref={this.realtimePicker}
        className="realtime-picker-container house"
        style={{ bottom: status === "started" ? 0 : "-50vw" }}
      >
        <p
          className="realtime-picker-header house"
          style={{ fontSize: screenWidth * 0.034 }}
        >
          Tap a Scare Power below to frighten the VR Player
        </p>
        <div className="mana-bar-container">
          <div
            className="mana-bar-active-wrapper"
            style={{ width: `${level}%` }}
          >
            <img
              className="mana-bar-active"
              style={{ minWidth: screenWidth * 0.9 }}
              src={manaBar}
              alt="mana-bar"
            />
          </div>
          <img className="mana-bar" src={manaBarBg} alt="mana-bar-bg" />
          <img
            className="mana-level"
            style={{
              height: screenWidth * 0.2,
              top: -screenWidth * 0.025,
              left: `${level + 1}%`,
              opacity
            }}
            src={manaLevel}
            alt="mana-level"
          />
        </div>
        <div className="realtime-picker house">
          <div
            className="realtime-trap-button-container"
            style={{
              opacity: mana < CoolDowns.flicker ? 0.3 : 1,
              cursor: mana < CoolDowns.flicker ? "default" : "pointer"
            }}
            onClick={this.onSelectRealtimeTrap(gameCode, "flicker", player.name, mana)}
          >
            <img className="realtime-trap-button house" src={flicker} alt="flicker" />
            <p className="trap-label">
              {" "}
              Flicker{" "}
            </p>
          </div>
          <div
            className="realtime-trap-button-container"
            style={{
              opacity: mana < CoolDowns.slam ? 0.3 : 1,
              cursor: mana < CoolDowns.slam ? "default" : "pointer"
            }}
            onClick={this.onSelectRealtimeTrap(gameCode, "slam", player.name, mana)}
          >
            <img className="realtime-trap-button house" src={slam} alt="slam" />
            <p className="trap-label">
              {" "}
              Slam{" "}
            </p>
          </div>
          <div
            className="realtime-trap-button-container"
            style={{
              opacity: mana < CoolDowns.whoosh ? 0.3 : 1,
              cursor: mana < CoolDowns.whoosh ? "default" : "pointer"
            }}
            onClick={this.onSelectRealtimeTrap(gameCode, "whoosh", player.name, mana)}
          >
            <img className="realtime-trap-button house" src={whoosh} alt="whoosh" />
            <p className="trap-label">
              {" "}
              Whoosh{" "}
            </p>
          </div>
        </div>
      </div>
    );
  }

  renderMap() {
    const { player, playerRoom, status } = this.props;
    const {
      activeFloor,
      activeRoom,
      confirmQuit,
      screenHeight,
      selectedTrap,
      pointsEarned,
      currScore,
      reason,
    } = this.state;

    if (activeRoom !== null) {
      return (
        <RoomPage
          roomId={activeRoom}
          screenHeight={screenHeight}
          back={this.onBackToFloorplan}
          backToFloor={this.onBackToFloor}
        />
      );
    }

    return (
      <div className="black-bg map-page-container">
        <div id="preload">&nbsp;</div>
        <div className="map-container house">
          <div className="banner blue-bg house">
            {status === "started" ? (
              <PlayerLocationBanner
                roomName={RoomNames[playerRoom]}
                onCloseClicked={this.setConfirmQuit(true)}
                timeRemaining={this.state.timeRemaining}
                score={this.state.currScore}
              />
            ) : (
              <PickRoomBanner
                name={player.name ? player.name.toUpperCase() : undefined}
              />
            )}
          </div>
          
          { status === "started" ? (            
          <ScoreTracker
              pointsEarned={pointsEarned}
              score={currScore}
              reason={reason}
            />) : (null)
          }
          {/* Quit modal */}
          <div className={`modal ${confirmQuit === true && "is-active"}`}>
            <div className="modal-background"></div>
            <div
              className="modal-card quit-container"
              style={{ overflow: "visible" }}
            >
              <ModalHeader
                message="Quit game?"
                onClose={this.setConfirmQuit(false)}
              />
              <section className="house modal-card-body trap-modal-container blue-bg">
                <Button
                  className="is-primary is-fullwidth is-large"
                  onClick={this.forceClearPlayer}
                >
                  Yes
                </Button>
                <Button
                  className="is-primary is-fullwidth is-large"
                  onClick={this.setConfirmQuit(false)}
                >
                  No
                </Button>
              </section>
            </div>
          </div>

          {status === "started" && activeFloor === 1 && (
            <p className="floor-label">First Floor</p>
          )}
          {status === "started" && activeFloor === 2 && (
            <p className="floor-label">Second Floor</p>
          )}

          <img
            className="map"
            style={{ height: screenHeight }}
            src={FloorImageMap[activeFloor + (status === "started" ? 2 : 0)]}
            alt={`floor${activeFloor}`}
          />

          <div className="map-controls-container">
            {status !== "started" && activeFloor === 1 && (
              <div>
                <div
                  className="room-container room1"
                  onClick={this.onSelectRoom(1)}
                ></div>
                <div
                  className="room-container room2_1"
                  onClick={this.onSelectRoom(2)}
                ></div>
                <div
                  className="room-container room2_2"
                  onClick={this.onSelectRoom(2)}
                ></div>
                <div
                  className="room-container room2_3"
                  onClick={this.onSelectRoom(2)}
                ></div>
                <div
                  className="room-container room3"
                  onClick={this.onSelectRoom(3)}
                ></div>
                <div
                  className="room-container room4"
                  onClick={this.onSelectRoom(4)}
                ></div>
                <div
                  className="room-container room5"
                  onClick={this.onSelectRoom(5)}
                ></div>
                <div
                  className="room-container room6_1"
                  onClick={this.onSelectRoom(6)}
                ></div>
                <div
                  className="room-container room6_2"
                  onClick={this.onSelectRoom(6)}
                ></div>
                <div
                  className="room-container room6_3"
                  onClick={this.onSelectRoom(6)}
                ></div>
              </div>
            )}
            {status !== "started" && activeFloor === 2 && (
              <div>
                <div
                  className="room-container room7"
                  onClick={this.onSelectRoom(7)}
                ></div>
                <div
                  className="room-container room8_1"
                  onClick={this.onSelectRoom(8)}
                ></div>
                <div
                  className="room-container room8_2"
                  onClick={this.onSelectRoom(8)}
                ></div>
                <div
                  className="room-container room8_3"
                  onClick={this.onSelectRoom(8)}
                ></div>
                <div
                  className="room-container room9"
                  onClick={this.onSelectRoom(9)}
                ></div>
                <div
                  className="room-container room10_1"
                  onClick={this.onSelectRoom(10)}
                ></div>
                <div
                  className="room-container room10_2"
                  onClick={this.onSelectRoom(10)}
                ></div>
                <div
                  className="room-container room10_3"
                  onClick={this.onSelectRoom(10)}
                ></div>
                <div
                  className="room-container room11"
                  onClick={this.onSelectRoom(11)}
                ></div>
                <div
                  className="room-container room12_1"
                  onClick={this.onSelectRoom(12)}
                ></div>
                <div
                  className="room-container room12_2"
                  onClick={this.onSelectRoom(12)}
                ></div>
                <div
                  className="room-container room12_3"
                  onClick={this.onSelectRoom(12)}
                ></div>
                <div
                  className="room-container room12_4"
                  onClick={this.onSelectRoom(12)}
                ></div>
                <div
                  className="room-container room12_5"
                  onClick={this.onSelectRoom(12)}
                ></div>
              </div>
            )}

            {this.renderTrapChoices()}
            {this.renderPlayerRoom()}

            <div className="buttons-container">
              {selectedTrap !== null && (
                <button className="button is-dark is-rounded is-medium">
                  {" "}
                  Clear Trap{" "}
                </button>
              )}
            </div>
            {status !== "started" && (
              <div className={`floor-picker blue-bg ${status === "started" ? "disabled" : " "}`}>
                <button
                  className="floor-choice"
                  style={
                    activeFloor === 1
                      ? { opacity: 1 }
                      : { opacity: 0.3, fontWeight: 400, cursor: "default" }
                  }
                  onClick={this.onSelectFloor(1)}
                >
                  {" "}
                  1st Floor{" "}
                </button>
                <div
                  style={{
                    borderWidth: 0,
                    borderRightWidth: 1,
                    borderColor: "white",
                    borderStyle: "solid",
                    height: "100%"
                  }}
                />
                <button
                  className="floor-choice"
                  style={
                    activeFloor === 2
                      ? { opacity: 1 }
                      : { opacity: 0.3, fontWeight: 400, cursor: "default" }
                  }
                  onClick={this.onSelectFloor(2)}
                >
                  {" "}
                  2nd Floor{" "}
                </button>
              </div>
            )}
            {status === "started" ? this.renderRealtimeChoices() : null}
          </div>
        </div>
      </div>
    );
  }

  renderScreen(message) {
    return (
      <div className="black-bg map-page-container">
        <p className="is-size-2">{message}</p>
      </div>
    );
  }

  renderEnd() {
    const { clearPlayer } = this.props;
    return (
      <div className="black-bg map-page-container">
        <div className="end-container">
          <p className="is-size-2 helvetica text-center">Game Ended!</p>
          <button
            className="rfbutton pointer margin-top mb-8"
            style={{ width: "60vw" }}
            onClick={clearPlayer}
          >
            Return to Home
          </button>
          <a
            className="is-size-6 bold helvetica text-center margin-top mb-2"
            style={{ color: "#DDDDDD", textDecoration: "underline" }}
            href="https://www.pixelcanvasstudios.com/reikosfragments"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get Reiko's Fragments on VR
          </a>
          <p className="is-size-4 bold helvetica text-center margin-top mb-2">
            Follow us!
          </p>
          <div className="flex flex-row justify-center">
            <a
              href="https://urlgeni.us/facebook/XwzG"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className="mr-8" src={fb} alt="fb.png" />
            </a>
            <a
              href="https://urlgeni.us/instagram/XwzG"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ig} alt="ig.png" />
            </a>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { status } = this.props;

    switch (status) {
      case "setup":
        return this.renderMap();
      case "ended":
        return this.renderEnd();
      default:
        return this.renderMap();
    }
  }
}
