import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InitializeWS } from '../../ws';
import { JoinGameForm } from '../JoinGameForm';
import title from '../../assets/images/title.png';
import background from '../../assets/images/splash.jpg';
import { joinGame } from '../../Game';
import { setGame, setPlayer } from '../../store/actions';

import ErrorMessage from '../ErrorMessage';
import SocialMediaContainer from '../SocialMediaContainer';

function CodePage() {
  const [error, setError] = useState(false);
  const [apiVersion, setApiVersion] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    fetch(`${process.env.REACT_APP_API_URL}/api/version`, { headers })
      .then((response) => {
        if (!response.ok) {
          console.error('Unable to fetch Reiko API version');
        }
        response.json().then((data) => setApiVersion(data.version));
      });
  }, []);

  const onJoin = async (code, name) => {
    try {
      const res = await joinGame(code, name);
      InitializeWS(res.gameCode, res.token);

      dispatch(setPlayer(res.gameCode, res.token, res.name));
      dispatch(setGame(res.game));
    } catch (e) {
      console.log(e);
      setError('Could Not That Join Game!');
    }
  };

  return (
    <div className="code-page">
      {error && (
        <ErrorMessage
          message="Unable to join game!"
          onClick={() => setError(null)}
        />
      )}
      <img className="background" src={background} alt="background" />
      <img className="title" src={title} alt="title" />

      <div className="interactables">
        <JoinGameForm onJoin={onJoin} />
        <SocialMediaContainer />
        <p className="version">
          reiko-web:
          {process.env.REACT_APP_VERSION}
          {apiVersion && ` reiko-api: ${apiVersion}`}
        </p>
      </div>
    </div>
  );
}

export default CodePage;
