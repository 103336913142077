import React from 'react';

function ShrineMapBackButton({ enabled, onClick }) {
  return (
    <button
      type="button"
      className={`shrine-map-back-button${enabled ? '' : ' disabled'}`}
      onClick={onClick}
      alt="back"
    />
  );
}

export default ShrineMapBackButton;
