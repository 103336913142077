import React, { Component } from "react";
import WS, { InitializeWS, CloseWS } from "../../ws";

import ScoreTracker from "../scoreTracker";
import ModalHeader from "../ModalHeader";
import { PlayerLocationBanner } from "../banner/PlayerLocationBanner";
import { PickSectionBanner } from "../banner/PickSectionBanner";
import { Button } from "react-bulma-components";
import ShrineMapBackButton from "../maps/ShrineMapBackButton.jsx";
import RealTimePicker from "./RealTimePicker.jsx";

import location from "../../assets/images/location.png";

import SelectTrapModal from "../SelectTrapModal";
import ShrineMap from "../maps/ShrineMap.jsx";
import shrineLocationImages from "../../assets/images/shrine/location/locations";

const locationNames = {
  0: "Graveyard",
  1: "Cave",
  2: "Shrine",
  3: "Entrance",
  4: "Torii Tunnel"
};

export default class ShrinePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmQuit: false,
      selectedTrap: null,
      screenHeight: window.innerHeight,
      isShowRealtimeTraps: false,
      isZoomed: false,
      focusedSection: null,
      timeRemaining: null,
      currScore: 0,
      trapName: null,
      pointsEarned: null,
    };
  }


  componentDidMount() {
    const { gameCode, token } = this.props.player;

    InitializeWS(gameCode, token);
    WS().handleOpen = this.handleWSOpen;
    WS().handleMessage = this.handleWSMessage;
    this.checkSession();

  }

  checkSession = async () => {
    const { clearPlayer, player, setGame } = this.props;
    const { gameCode, token } = player;
    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      let r = await fetch(
        `${process.env.REACT_APP_API_URL}/api/game/map?gameCode=${gameCode}&token=${token}`,
        {
          method: "GET",
          headers: headers
        }
      );

      if (r.status !== 200) {
        clearPlayer();
        return;
      }
      setGame(await r.json());
    } catch (e) {
      clearPlayer();
    }
  };

  handleWSOpen = () => {
    console.log("connected websocket");
  };

  handleWSMessage = msg => {
    const {
      setKillPlayer,
      setPlayerRoom,
      setStatus,
      updateTraps,
      setScores,
    } = this.props;

    msg = JSON.parse(msg);

    const {
      score,
      pointsEarned,
      reason,
      scores,
      event,
      locations,
      room,
      status,
      killPlayer,
      time,
    } = msg;

    const {
      name
    } = this.props.player

    switch (event) {
      case "score_updated":
        if (name === msg.name) {
          this.setState({
            currScore: score,
            pointsEarned: pointsEarned,
            reason: reason,
          })
        }
        break;
      case "choose_trap":
        updateTraps(locations);
        break;
      case "start":
        setStatus("started");
        break;
      case "player_room":
        setPlayerRoom(room);
        break;
      case "end":
        CloseWS();
        setScores(scores);
        setStatus(status);
        if (killPlayer) {
          setKillPlayer(killPlayer);
        }
        break;
      case "time_remaining":
        this.setState({
          timeRemaining: time
        })
        break;
      default:
        break;
    }
  };

  focusOnSection = section => this.setState({ focusedSection: section });

  forceClearPlayer = async () => {
    const { clearPlayer } = this.props;
    clearPlayer();
  };

  setConfirmQuit = confirmQuit => () => {
    this.setState({ confirmQuit: confirmQuit });
  };

  setSelectedTrap = i => {
    this.setState({ selectedTrap: i });
  };

  onSelectTrap = selectedLocation => trap => async () => {
    const { player, updateTraps } = this.props;

    this.setSelectedTrap(null);

    const trapTypes = {
      headless: 1,
      thunder: 2,
      scream: 3
    };

    try {
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      let r = await fetch(`${process.env.REACT_APP_API_URL}/api/game/traps`, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          ...player,
          location: Number(selectedLocation),
          trap: trapTypes[trap]
        })
      });
      if (r.status !== 200) {
        this.setState({ error: "Could Not Choose Trap!" });
        return;
      }

      r = await r.json();
      updateTraps(r.locations);

      this.setState({ selectedLocation: null });
    } catch (e) {
      this.setState({ error: "Could Not Place Trap!" });
      return;
    }
  };

  renderPlayerRoom() {
    const { playerRoom } = this.props; //actual in-game player
    if (!playerRoom) {
      return null;
    }

    const { x, y } = {
      1: { x: 28, y: 33 },
      2: { x: 25, y: 52 },
      3: { x: 64, y: 20 },
      4: { x: 69, y: 38 },
      5: { x: 77.5, y: 63 },
      6: { x: 45.5, y: 66 },
      7: { x: 35.5, y: 35 },
      8: { x: 30, y: 54 },
      9: { x: 70, y: 24 },
      10: { x: 75, y: 47 },
      11: { x: 78, y: 68 },
      12: { x: 55, y: 65 }
    }[playerRoom];

    return (
      <img
        className="player-location-icon"
        style={{ top: `${y}%`, left: `${x}%` }}
        src={location}
        alt="player_location"
      />
    );
  }

  render() {
    const {
      player:{
        gameCode,
        name
      },
      playerRoom,
      status,
      map,
      trapLocations
    } = this.props;

    const {
      confirmQuit,
      selectedTrap,
      focusedSection,
      timeRemaining,
      currScore,
      pointsEarned,
      reason,
    } = this.state;
    return (
      <div className="black-bg map-page-container">
          <header className="banner blue-bg shrine">
            <ShrineMapBackButton
              enabled={focusedSection !== null}
              onClick={() => this.focusOnSection(null)}
            />
            {status === "started" ? (
              <PlayerLocationBanner
                roomName={locationNames[playerRoom]}
                onCloseClicked={this.setConfirmQuit(true)}
                timeRemaining={timeRemaining}
                score={currScore}
              />
            ) : (
              <PickSectionBanner
                name={name ? name.toUpperCase() : undefined}
                map={map}
              />
            )}
          </header>

          {/* Real Time Score Modal */}
          { status === "started" ? (            
          <ScoreTracker
              pointsEarned={pointsEarned}
              score={currScore}
              reason={reason}
            />) : (null)
          }
          <div className='shrine-map-container'>
          <ShrineMap
            status={status}
            placedTraps={trapLocations}
            focusedSection={status !== "started" ? focusedSection : null}
            focusOnSection={this.focusOnSection}
            handleTrapButtonClick={this.setSelectedTrap}
          />
          </div>


          {/* Quit modal */}
          <div className={`modal ${confirmQuit === true && "is-active"}`}>
            <div className="modal-background"></div>
            <div
              className="modal-card quit-container"
              style={{ overflow: "visible" }}
            >
              <ModalHeader
                message="Quit game?"
                onClose={this.setConfirmQuit(false)}
              />
              <section className="modal-card-body trap-modal-container blue-bg">
                <div className='modal-button-wrapper'>
                <Button
                  className="button is-fullwidth is-large"
                  onClick={this.forceClearPlayer}
                >
                  YES
                </Button>
                <Button
                  className="button is-fullwidth is-large"
                  onClick={this.setConfirmQuit(false)}
                >
                  NO
                </Button>
                </div>
              </section>
            </div>
          </div>

          {status !== "started" && selectedTrap && (
            <SelectTrapModal
              enabled={selectedTrap !== null}
              locationImage={shrineLocationImages[selectedTrap]}
              handleTrapSelection={this.onSelectTrap(selectedTrap)}
              onClose={() => this.setSelectedTrap(null)}
            />
          )}

        {status === "started" ? (
        <RealTimePicker
          gameCode={gameCode}
          name={name}
          map={map}
        /> ) : null }
      </div>
    );
  }
}
