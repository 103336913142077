export const getMap = async (code, token) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/api/game/map?gameCode=${code}&token=${token}`,
    {
      method: 'GET',
      headers,
    },
  );

  if (res.status !== 200) {
    throw new Error(res.text());
  }

  return await res.json();
};

export const joinGame = async (code, name) => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');

  const res = await fetch(`${process.env.REACT_APP_API_URL}/api/game/join`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ gameCode: code, name: name.toUpperCase() }),
  });

  if (res.status !== 200) {
    throw new Error(res.text());
  }

  return await res.json();
};
