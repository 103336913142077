import headlessIcon from '../../assets/images/headless.png';
import thunderIcon from '../../assets/images/thunder.png';
import screamIcon from '../../assets/images/scream.png';

const trapImages = {
  headless: headlessIcon,
  thunder: thunderIcon,
  scream: screamIcon,
};

export default trapImages;
