import React from 'react';

import TrapButton from './TrapButton';
import ModalHeader from './ModalHeader';

import headless from '../assets/images/headless.png';
import thunder from '../assets/images/thunder.png';
import scream from '../assets/images/scream.png';

export function SelectTrapModal({
  enabled,
  locationImage,
  handleTrapSelection,
  onClose,
}) {
  return (
    <div className={`modal ${enabled && 'is-active'}`}>
      <div className="modal-background" />
      <div className="modal-card" style={{ overflow: 'visible', padding: 0 }}>
        <ModalHeader message="Choose trap" onClose={onClose} />
        <img src={locationImage} alt="selected-location" />
        <section className="modal-card-body trap-modal-container blue-bg">
          <TrapButton
            label="Headless"
            image={headless}
            onClick={handleTrapSelection('headless')}
          />
          <TrapButton
            label="Thunder"
            image={thunder}
            onClick={handleTrapSelection('thunder')}
          />
          <TrapButton
            label="Scream"
            image={scream}
            onClick={handleTrapSelection('scream')}
          />
        </section>
      </div>
    </div>
  );
}

export default SelectTrapModal;
