import React from 'react';

export const ModalHeader = ({ message, onClose }) => (
  <header className="trap-modal-header modal-card-head blue-bg">
    <div className="trap-modal-header-left" />
    <div className="trap-modal-header-center">
      <p className="modal-card-title modal-title">{message}</p>
    </div>
    <div className="trap-modal-header-right">
      <button
        className="delete is-large"
        aria-label="close"
        onClick={onClose}
      />
    </div>
  </header>
);

export default ModalHeader;
