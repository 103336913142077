/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

import manaBar from '../../assets/images/mana_bar_blue.png';
import manaBarBg from '../../assets/images/mana_bar_bg.png';
import manaLevel from '../../assets/images/mana_level.png';
import WS from '../../ws';
import { useInterval } from '../../hooks/use-interval';

const FULL_MANA_SECS = 45;

const mapID = {
  House: 0,
  Shrine: 1,
};

const coolDowns = {
  0: {
    0: ['flicker', 0.27],
    1: ['slam', 0.64],
    2: ['whoosh', 1.0],
  },
  1: {
    0: ['fog', 0.27],
    1: ['whoosh', 0.64],
    2: ['hands', 1.0],
  },
};

const RealTimePicker = ({ gameCode, name, map }) => {
  const [mana, setMana] = useState(0);
  const level = (90 * mana);
  let opacity = 0.65;
  const flash = 0.7 / FULL_MANA_SECS;

  const trapName1 = coolDowns[mapID[map]][0][0];
  const trapName2 = coolDowns[mapID[map]][1][0];
  const trapName3 = coolDowns[mapID[map]][2][0];

  const trapEffect1 = coolDowns[mapID[map]][0][1];
  const trapEffect2 = coolDowns[mapID[map]][1][1];
  const trapEffect3 = coolDowns[mapID[map]][2][1];


  Object.values(coolDowns[mapID[map]]).forEach((v) => {
    if (v[1] < mana && mana < v[1] + flash) {
      const factor = 1 - (mana - v[1]) / flash;
      opacity = factor + (1 - factor) * opacity;
    }
  });
  if (mana >= 1) {
    opacity = 1;
  }

  useInterval(() => {
    if (mana < 1) {
      setMana(mana + 0.01 / FULL_MANA_SECS);
    }
  }, 1000 / 100);
  
  const onSelectRealtimeTrap = (trapName, cd) => {
    
    return (e) => {
      e.preventDefault();
      if (mana < cd) {
        return;
      }
      WS().sendRealTimeTrap(gameCode, trapName, name);
      setMana(mana - cd);
    };
  };

  return (
    <footer
      className="realtime-picker-container"
    >
      <p className="realtime-picker-header">
        Tap a Scare Power below to frighten the VR Player
      </p>
      <div className="mana-bar-container">
        <div
          className="mana-bar-active-wrapper"
          style={{ width: `${level}%` }}
        >
          <img
            className="mana-bar-active"
            src={manaBar}
            alt="mana-bar"
          />
        </div>
        <img
          className="mana-bar"
          src={manaBarBg}
          alt="mana-bar-bg"
        />
        <img
          className="mana-level "
          src={manaLevel}
          alt="mana-level"
          style={
              { // Look into React Spring for alternative methods of animation
                left: `${(level + 1)}%`,
                opacity,
              }
            }
        />
      </div>
      {/* End Mana Bar Container */}
      {/* Start Real Time Picker Container */}
      <div className="realtime-picker">
        <div
          className={`realtime-trap-button-container-1 
          ${mana > trapEffect1 ? 'active' : 'inactive'}`}
        >
          <button
            type="button"
            className={`realtime-trap-button 
          ${trapName1} 
          ${mana > trapEffect1 ? 'active' : 'inactive'}`}
            onClick={
              onSelectRealtimeTrap(`${trapName1}`, trapEffect1)
            }
            alt={trapName1}
          />
          <p className="trap-label">{trapName1}</p>
        </div>
        <div
          className={`realtime-trap-button-container-2 
          ${mana > trapEffect2 ? 'active' : 'inactive'}`}
        >
          <button
            type="button"
            className={`realtime-trap-button 
          ${trapName2} 
          ${mana > trapEffect2 ? 'active' : 'inactive'}`}
            onClick={
              onSelectRealtimeTrap(`${trapName2}`, trapEffect2)
            }
            alt={trapName2}
          />
          <p className="trap-label">{trapName2}</p>
        </div>
        <div
          className={`realtime-trap-button-container-3 
          ${mana > trapEffect3 ? 'active' : 'inactive'}`}
        >
          <button
            type="button"
            className={`realtime-trap-button 
          ${trapName3} 
          ${mana > trapEffect3 ? 'active' : 'inactive'}`}
            onClick={
              onSelectRealtimeTrap(`${trapName3}`, trapEffect3)
            }
            alt={trapEffect3}
          />
          <p className="trap-label">{trapName3}</p>
        </div>
      </div>
    </footer>
  );
};


export default RealTimePicker;
