import React, { useEffect, useState } from 'react';
import { useTimeoutFn } from 'react-use';

const trapNames = {
  realtime_fog: 'fog',
  realtime_whoosh: 'whoosh',
  realtime_hands: 'hands',
  realtime_flicker: 'flicker',
  realtime_slam: 'slam',
  doll_triggered: 'doll trap',
  hands_triggered: 'hands',
  whoosh_triggered: 'whoosh'
};

function ScoreTracker({ pointsEarned, score, reason }) {
  console.log(reason)
  const [enabled, setEnabled] = useState(false);
  const trapName = trapNames[reason];
  
  useEffect(() => {
    if (score) {
      setEnabled(true);
    }
  }, [score]);
  const [,, reset] = useTimeoutFn(() => setEnabled(false), 1850);
  // eslint-disable-next-line
  useEffect(() => reset(), [enabled]);


  return (
    <div className={`score-tracker-container ${enabled ? 'enabled' : 'disabled'} ${!reason ? 'no-anim' : 'anim'} `}>
      <div className="score-tracker-content">
        <div className="score-tracker-content-top">
          <p className="score-tracker-content-top trap">
            {trapName}
          </p>
        </div>
        <div className="score-tracker-content divider" />
        <div className="score-tracker-content-bottom">
          <p className="score-tracker plus">+</p>
          <p className="score-tracker score">{pointsEarned}</p>
        </div>
      </div>
    </div>
  );
}

export default ScoreTracker;
