import { connect } from 'react-redux';
import HousePage from './HousePage';
import { actions } from '../../store';

import { RoomMap } from '../constants';

const mapStateToProps = (state) => {
  const rooms = [[], [], [], [], [], [], [], [], [], [], [], []];
  (state.game.locations || []).forEach((l) => {
    const roomId = RoomMap[l.id];
    rooms[roomId - 1].push(l);
  });

  return {
    player: state.player,
    playerRoom: state.game.playerRoom,
    rooms,
    status: state.game.status,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearPlayer: () => dispatch(actions.clearPlayer()),
  clearGame: () => dispatch(actions.clearGame()),
  setGame: (game) => dispatch(actions.setGame(game)),
  setKillPlayer: (killPlayer) => dispatch(actions.setKillPlayer(killPlayer)),
  setPlayerRoom: (room) => dispatch(actions.setPlayerRoom(room)),
  setScores: (scores) => dispatch(actions.setScores(scores)),
  setStatus: (status) => dispatch(actions.setStatus(status)),
  updateTraps: (traps) => dispatch(actions.updateMap(traps)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HousePage);
