import React from 'react';

import trapImages from './TrapImages';

function PlacedTrap({ type, player }) {
  return (
    <div className="shine-map-trap-choice">
      <img
        className="shrine-map-trap-placed"
        src={trapImages[type]}
        alt="placed-trap"
      />
      <p>{player}</p>
    </div>
  );
}

export default PlacedTrap;
