import ReconnectingWebsocket from "reconnecting-websocket";

class Websocket {
  constructor(gameCode, token) {
    const wsURL = process.env.REACT_APP_WS_URL
      ? process.env.REACT_APP_WS_URL
      : `wss://${window.location.host}`;
    this.ws = new ReconnectingWebsocket(
      `${wsURL}/ws?gameCode=${gameCode}&token=${token}`
    );
    this.ws.addEventListener("open", e => this.handleOpen && this.handleOpen(e));
    this.ws.addEventListener("message", e => this.handleMessage && this.handleMessage(e.data));
  }

  send = (obj) => this.ws.send(JSON.stringify(obj));

  close = () => {
    this.ws.close();
  };

  sendRealTimeTrap = (gameCode, type, name) => this.ws.send(JSON.stringify({
    event: "realtime_trap",
    gameCode,
    name,
    type
  }));

}

let ws;
export const InitializeWS = (gameCode, token) => {
  if (!ws) {
    ws = new Websocket(gameCode, token);
  }
};

export const CloseWS = () => {
  if (ws) {
    ws.close();
    ws = undefined;
  }
};

export default () => {
  return ws;
};
